<template>
  <div>
    <!-- Filters -->
    <play-list-list-filters
        :role-filter.sync="roleFilter"
        :plan-filter.sync="planFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :status-options="statusOptions"
        @filter="fetchPlaylist"
    />

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @change="changePagePlaylist({page:1 , itemsPerPage: $event})"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative pb-3"
          :items="playlists"
          :per-page="perPage"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >
        <!-- Column: Music -->
        <template #cell(list)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.thumbnail"
                  :text="avatarText(data.item.name)"
                  :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
                :to="{ name: 'apps-moods-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small
                v-if="data.item.genre"
                class="text-muted"
            >{{
                data.item.genre.name
              }}</small>
          </b-media>
        </template>
        <!-- Column: Genre -->
        <template #cell(music)="data">
          <div class="text-nowrap">
            <span>
              <i class="WMi-music"/>
            </span>
            <span class="align-text-top text-capitalize">{{
                data.item.music.length
              }}</span>
          </div>
        </template>
        <template #cell(genre)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
                data.item.genre.name_ar
              }}</span>
          </div>
        </template>
        <!-- Column: Artist -->
        <!-- <template #cell(artist)="data">
          <div class="text-nowrap d-flex align-items-center">
            <span>
              <i class="WMi-user"></i>
            </span>
            <b-link
              :to="{ name: 'apps-artists-view', params: { id: data.item.artist.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.artist.name }}
            </b-link>
          </div>
        </template> -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
                :to="{
                name: 'apps-moods-view',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
                :to="{
                name: 'apps-moods-edit',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="$_openModal('add_song_to_play_list',{model:{id:data.item.id}})">
              <feather-icon icon="PlusIcon"/>
              <span class="align-middle ml-50">Add/Remove Music</span>
            </b-dropdown-item>

            <b-dropdown-item @click="updateSort(data.item.id)">
              <feather-icon icon="ArrowUpIcon"/>
              <span class="align-middle ml-50">Send to top</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deletePlaylist(data.item.id)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
                class="text-muted"
            >Showing {{ pagination.pageStart }} to
              {{ pagination.pageStop }} of
              {{ pagination.itemsLength }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="pagination.itemsLength"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePagePlaylist({ page: $event , itemsPerPage: perPage})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AddMusicToPlaylistList/>
  </div>
</template>

<script>
import AddMusicToPlaylistList from '@/views/apps/moods/AddMusicToPlayList.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, onMounted} from '@vue/composition-api'
import {avatarText} from '@core/utils/filter'
import PlayListListFilters from './PlayListListFilters.vue'
import usemoodList from './useMoodList'
import userStoreModule from '../user/userStoreModule'
import moodsRepository from "@/abstraction/repository/moodsRepository";

const moodRepository = new moodsRepository()
export default {
  components: {
    PlayListListFilters,
    AddMusicToPlaylistList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const {
      perPageOptions,
      playlists,
      pagination,
      perPage,
      sortBy,
      tableColumns,
      currentPage,
      fetchPlaylist,
      deletePlaylist,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = usemoodList()

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const roleOptions = [
      {label: 'Admin', value: 'admin'},
      {label: 'Author', value: 'author'},
      {label: 'Editor', value: 'editor'},
      {label: 'Maintainer', value: 'maintainer'},
      {label: 'Subscriber', value: 'subscriber'},
    ]

    const planOptions = [
      {label: 'Basic', value: 'basic'},
      {label: 'Company', value: 'company'},
      {label: 'Enterprise', value: 'enterprise'},
      {label: 'Team', value: 'team'},
    ]

    const statusOptions = [
      {label: 'Pending', value: 'pending'},
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'},
    ]

    onMounted(() => {
      fetchPlaylist()
    })

    const changePagePlaylist = async paginate => {
      await fetchPlaylist(paginate)
    }

    const updateSort = async id => {
      await moodRepository.updateSort(id)
      await fetchPlaylist({page: currentPage, perPage: perPage})
    }

    return {
      playlists,
      pagination,
      // Filter
      avatarText,
      roleOptions,
      planOptions,
      statusOptions,
      perPageOptions,
      changePagePlaylist,
      fetchPlaylist,
      perPage,
      currentPage,
      sortBy,
      tableColumns,
      deletePlaylist,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      updateSort,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
  color: $red;
}
</style>
